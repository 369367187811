import { Modal } from 'bootstrap';
import naja from "naja";

class FormsConfirmDialogExtension {

    initialize(naja) {
        this.initializeForms();
        naja.addEventListener('complete', this.initializeForms.bind(this));
    }

    initializeForms(event) {
        $('form[data-form-confirm-dialog="true"]').each((index, form) => {
            const formConfirmDialog = new FormConfirmDialog($(form));
            formConfirmDialog.initialize();
        });
    }
}
export default FormsConfirmDialogExtension;

class FormConfirmDialog {
    constructor(form) {
        this.form = form;
        this.initialValues = {};
        this.modalOpen = false;
        this.callbackSend = false;
        this.enabledRestore = !!this.form.find('.on-change-reload-form').length;

        this.handleInteraction = this.handleInteraction.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.handleBeforeUnload = this.handleBeforeUnload.bind(this);
        this.handleRestoreChangedState = this.handleRestoreChangedState.bind(this);
    }

    loadValue(element) {
        let currentValue;
        if ($(element).attr('type') === 'checkbox') {
            currentValue = $(element).prop('checked');
        } else if ($(element).is('select[multiple]')) {
            currentValue = $(element).val() || [];
        } else {
            currentValue = $(element).val();
        }
        this.initialValues[element.id] = currentValue;
    }

    handleInputChange(element) {
        let id =  $(element).attr('id');
        element = $('#' + id);

        let currentValue;
        if ($(element).attr('type') === 'checkbox') {
            currentValue = $(element).prop('checked');
        } else if ($(element).is('select[multiple]')) {
            currentValue = element.val() || [];
        } else {
            currentValue = element.val();
        }

        if (Array.isArray(currentValue) && Array.isArray(this.initialValues[id])) {
            if (currentValue.toString() !== this.initialValues[id].toString()) {
                element.addClass('changed');
            } else {
                element.removeClass('changed');
            }
        } else if (currentValue !== this.initialValues[id]) {
            element.addClass('changed');
        } else {
            element.removeClass('changed');
        }
    }

    handleRestoreChangedState(event) {
        if (!this.enabledRestore) {
            return;
        }
        this.form.find('input, textarea, select').each((index, element) => {
            this.handleInputChange(element);
        });
    }

    isFormChanged() {
        if (this.form.attr('data-disable-change-warning')) {
            return false;
        }

        let changed = false;
        this.form.find('input, textarea, select').each((index, element) => {
            if ($(element).hasClass('changed')) {
                changed = true;
                return true;
            }
        });

        return changed;
    }

    cleanChangedInputs() {
        this.form.find('input, textarea, select').each((index, element) => {
            $(element).removeClass('changed');
        });
    }

    initialize() {
        // console.log(this.form.attr('id'));
        // this.form.load(this.form.attr('id'));
        let id = this.form.attr('id');
        this.form = $(`#${id}`);
        if (this.form.hasClass('ignore-changes')) {
            return;
        }

        if (this.form.closest('.confirm-dialog-wrapper').length) {
            let wrapper = this.form.closest('.confirm-dialog-wrapper');
            if (wrapper.hasClass('confirm-dialog-wrapper-initialized')) {
                return;
            }
            wrapper.addClass('confirm-dialog-wrapper-initialized');
        } else {
            if (this.form.hasClass('confirm-dialog-initialized')) {
                this.handleRestoreChangedState();
                return;
            }
            this.form.addClass('confirm-dialog-initialized');
        }


        this.form.find('input, textarea, select').each((index, element) => {
            this.loadValue(element);

            $(element).on('change', () => {
                this.handleInputChange(element);
            });

            $(element).on('keyup', () => {
                this.handleInputChange(element);
            });
        });

        naja.uiHandler.addEventListener('interaction', this.handleInteraction);
        $(document).on('click', 'a', this.handleClick);
        window.addEventListener('beforeunload', this.handleBeforeUnload);
        naja.addEventListener('complete', this.handleRestoreChangedState);

        const modal = this.form.closest('.modal');
        if (modal.length) {
            modal.on('hide.bs.modal', (e) => {
                if (!this.callbackSend && this.isFormChanged()) {
                    e.preventDefault();
                    this.showConfirmDialog(() => {
                        modal.find('input[type=submit]').trigger('click');
                    });
                }
            });
        }
    }

    handleInteraction(event) {
        let {element} = event.detail;

        element = $(element);

        // if (element.closest('form.ajax').length && this.isFormChanged() && !this.modalOpen && $.contains(document.body, this.form[0])) {
        //     event.preventDefault();
        //     this.showConfirmDialog(() => {
        //         naja.makeRequest('GET', element.attr('href'), null, {history: true});
        //     });
        // }
        if (element.closest('form.ajax').length && element.attr('name') === 'back' && this.isFormChanged() && !this.modalOpen && $.contains(document.body, this.form[0])) {
            event.preventDefault();
            this.showConfirmDialog(() => {
                element.trigger('click');
            });
        } else {
            this.callbackSend = true;
        }

        if (element.attr('name') === '_submit') {
            this.cleanChangedInputs();
        }
    }

    handleClick(event) {
        const target = $(event.target);
        if ((!target.closest('form.ajax').length || !target.hasClass('ajax')) && this.isFormChanged() && !this.modalOpen && $.contains(document.body, this.form[0])) {
            event.preventDefault();
            this.showConfirmDialog(() => {
                window.location.href = target.attr('href');
            });
        } else {
            this.callbackSend = true;
        }
    }

    handleBeforeUnload(event) {
        if (this.isFormChanged() && !this.callbackSend) {
            event.preventDefault();
            event.returnValue = '';
        }
    }

    showConfirmDialog(yesCallback) {
        const modal = document.createElement('div');
        modal.classList.add('modal', 'fade');
        modal.tabIndex = -1;
        modal.role = 'dialog';
        modal.innerHTML = `
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">Ve formuláři došlo ke změnám.</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-footer d-flex justify-content-between">
                    <button type="button" class="btn btn-secondary">Zpět</button>
                    <button type="button" class="btn btn-warning">Neukládat a pokračovat</button>
                    <button type="button" class="btn btn-success">Uložit a pokračovat</button>
                </div>
            </div>
        </div>
        `;

        document.body.appendChild(modal);

        const bootstrapModal = new Modal(modal);
        modal.querySelector('.btn-success').addEventListener(
            'click', (ev) => {
                if (!window.Nette.validateForm(this.form[0])) {
                    return;
                }

                if (this.form.hasClass('ajax')) {
                    naja.uiHandler.submitForm(this.form[0]).then((response) => {
                        this.destroyCallbacks();
                        bootstrapModal.hide();
                        this.modalOpen = false;
                        this.callbackSend = true;
                        yesCallback();
                        this.callbackSend = false;
                    });
                } else {
                    bootstrapModal.hide();
                    this.modalOpen = false;
                    this.form.submit();
                }
            }
        );
        modal.querySelector('.btn-warning').addEventListener(
            'click', () => {
                this.destroyCallbacks();

                bootstrapModal.hide();

                if ($('.modal.show').length) {
                    $('.modal-backdrop').remove();
                    $('.modal.show').remove();

                    this.modalOpen = false;
                    this.callbackSend = false;
                } else {
                    this.modalOpen = false;
                    this.callbackSend = true;
                    yesCallback();
                    this.callbackSend = false;
                }

                $('.modal-backdrop').remove();
                $('.modal.show').remove();
            }
        );
        modal.querySelector('.btn-secondary').addEventListener(
            'click', () => {
                bootstrapModal.hide();
                this.modalOpen = false;
            }
        );
        modal.addEventListener('hidden.bs.modal', () => {
            this.modalOpen = false;
        });

        this.modalOpen = true;
        bootstrapModal.show();
    }

    destroyCallbacks() {
        naja.uiHandler.removeEventListener('interaction', this.handleInteraction);
        $(document).off('click', 'a', this.handleClick);
        window.removeEventListener('beforeunload', this.handleBeforeUnload);
    }
}
